<template>
  <div style="margin: 40px 60px">
    <el-row :gutter="10">
      <el-col :span="4">
        <router-link class="func_block" style="background:#946eff;" to="default">
          <div class="hd">
            <el-row>
              <el-col>通用管理</el-col>
              <el-col></el-col>
            </el-row>
          </div>
          <div class="bd">
            <i class="ti-world" />
          </div>
        </router-link>
      </el-col>
      <el-col :span="4">
        <router-link class="func_block" style="background:#33cc66;" to="default">
          <div class="hd">
            <el-row>
              <el-col :span="20">维修管理</el-col>
<!--              <el-col :span="4" style="text-align:right">0</el-col>-->
            </el-row>
          </div>
          <div class="bd">
            <i class="ti-hummer" />
          </div>
        </router-link>
      </el-col>
      <el-col :span="4">
        <router-link class="func_block" style="background:#3aa8ec;" to="default">
          <div class="hd">
            <el-row>
              <el-col>物料管理</el-col>
              <el-col></el-col>
            </el-row>
          </div>
          <div class="bd">
            <i class="ti-shopping-cart-full" />
          </div>
        </router-link>
      </el-col>
      <el-col :span="4">
        <router-link class="func_block" style="background:#946eff;" to="default">
          <div class="hd">
            <el-row>
              <el-col :span="20">固定资产</el-col>
<!--              <el-col :span="4" style="text-align:right">0</el-col>-->
            </el-row>
          </div>
          <div class="bd">
            <i class="ti-harddrives" />
          </div>
        </router-link>
      </el-col>
      <el-col :span="4">
        <router-link class="func_block" style="background:#33cc66;" to="default">
          <div class="hd">
            <el-row>
              <el-col :span="20">档案管理</el-col>
<!--              <el-col :span="4" style="text-align:right">0</el-col>-->
            </el-row>
          </div>
          <div class="bd">
            <i class="ti-files" />
          </div>
        </router-link>
      </el-col>
      <el-col :span="4">
        <router-link class="func_block" style="background:#3aa8ec;" to="default">
          <div class="hd">
            <el-row>
              <el-col>安全巡检</el-col>
              <el-col></el-col>
            </el-row>
          </div>
          <div class="bd">
            <i class="ti-shield" />
          </div>
        </router-link>
      </el-col>
    </el-row>
  </div>
</template>
<style scoped>
  .func_block{width: 224px; height: 110px; border:solid 1px #ffffffff; border-radius: 10px; display: block}
  .func_block .hd {height: 34px; border-bottom:solid 2px rgba(255,255,255,0.05); color: #fff; font-size: 16px; line-height: 34px; padding: 0px 15px;}
  .func_block .bd {border-top:solid 2px rgba(152,152,152,0.05); color: #fff; line-height: 72px; text-align: center; font-size: 35px; }
</style>
<script>
export default {
  name: "default"
}
</script>
